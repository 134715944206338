var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container page"},[_c('van-nav-bar',{staticClass:"nav-bar",attrs:{"title":_vm.$t('mine.Setfundpassword')},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left","color":"#fff"},on:{"click":function($event){return _vm.back()}}})]},proxy:true}])}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('mine.enterthefundpassword')))]),_c('van-password-input',{attrs:{"value":_vm.opw,"length":4,"gutter":10,"focused":_vm.oshowKeyboard},on:{"focus":function($event){_vm.oshowKeyboard = true
                    _vm.tshowKeyboard = false}}}),_c('van-number-keyboard',{attrs:{"show":_vm.oshowKeyboard},on:{"input":function($event){_vm.opw.length !== 3
                        ? (_vm.oshowKeyboard = true)
                        : (_vm.tshowKeyboard = true)},"blur":function($event){_vm.oshowKeyboard = false}},model:{value:(_vm.opw),callback:function ($$v) {_vm.opw=$$v},expression:"opw"}})],1),_c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('mine.enterthefundpasswordagain')))]),_c('van-password-input',{attrs:{"value":_vm.tpw,"length":4,"gutter":10,"focused":_vm.tshowKeyboard},on:{"focus":function($event){_vm.tshowKeyboard = true
                    _vm.oshowKeyboard = false}}}),_c('van-number-keyboard',{attrs:{"show":_vm.tshowKeyboard},on:{"input":function($event){_vm.tpw.length !== 3
                        ? (_vm.tshowKeyboard = true)
                        : (_vm.tshowKeyboard = false)
                    _vm.oshowKeyboard = false},"blur":function($event){_vm.tshowKeyboard = false}},model:{value:(_vm.tpw),callback:function ($$v) {_vm.tpw=$$v},expression:"tpw"}})],1),_c('van-button',{staticClass:"sub-btn",attrs:{"type":"default"},on:{"click":function($event){return _vm.setPayPassword()}}},[_vm._v(_vm._s(_vm.$t('common.submit')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
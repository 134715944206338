export default {
    // 通用
    common: {
      app_name: "",
      tab_bar: {
        tab_home: "ホーム",
        tab_reserve: "予約",
        tab_concubine: "妃を選ぶ",
        tab_video: "ビデオ",
        tab_mine: "マイページ",
      },
      view_more: "もっと見る",
      play: "再生",
      finished_text: "もうありません",
      description: "データがありません！",
      Details: "詳細",
      disabled: "機能が無効化されています！",
      offline: "アカウントはオフラインです",
      Sure: "確定",
      Cancel: "キャンセル",
      notset: "未設定",
      Hasbeenset: "設定済み",
      logout: "アボート",
      currency: "円",
      submit: "送信",
      save: "保存",
      success: "完了",
    },
    login: {
      login: "ログイン",
      forget_pwd: "パスワードを忘れましたか？",
      to_reg: "ユーザー名なし",
      inp_username: "ユーザー名",
      inp_pwd: "パスワード",
    },
    register: {
      title: "登録する",
      inp_user: "ユーザー名",
      inp_pass: "パスワード",
      inp_code: "招待コード",
      agreement: '"アカウント開設契約"の条項を理解し、同意します',
      reg_btn: "登録する",
      click_agreement: "下のアカウント開設契約にチェックを入れてください！",
    },
    game: {
      title: "予約ホール",
      all: "すべて",
      xzmoney: "予約済み",
      create_time: "予約時間",
      update_time: "決済時間",
      Gamerules: "ゲームルール",
      gametip: "ゲームのヒント",
      gamedetial: "選択可能な数値の中から番号を選んで賭けます",
      Winninginstructions: "当選説明",
      Winningtip: "3つの当選番号の合計が11〜18は大;合計が3〜10は小です。",
      Bettingexample: "賭けの例",
      Bettingexampletip: "賭けのプラン：小;当選番号：123、つまり小です",
      missionlist: "タスクリスト",
      AvailableBalance: "口座残高：",
      numberselection: "現在の番号選択",
      Amountperbet: "1回あたりの金額",
      Pleaseentertheamount: "金額を入力してください",
      total: "合計",
      total1: "合計",
      note: "注",
      Clearorder: "注文をクリア",
      submission: "確認して送信",
      Issue: "履歴",
      Winningnumbers: "結果",
      Notselected: "未選択",
      Wrongamount: "金額が間違っています！",
      selectanumber: "番号を選択してください",
      Pleasefillintheamount: "金額を入力してください！",
      servicetorecharge: "残高不足です。カスタマーサービスにお問い合わせください！",
      Unselected: "未選択",
      thistask: "このタスクを受け取るには管理者に連絡してください！",
      Thelotterydraw: "抽選に成功しました。期号：",
      Tasktype: "タスクタイプ",
      timeleft: "購入時間が残っています",
    },
    //   ホーム
    home: {
      rec_tasks: "認証エリア",
      pupu_rank: "人気ランキング",
      rec_hot: "ホットな推奨",
      getting: "取得中......",
      refresh_successful: "リフレッシュ成功",
    },
    //   マイページ
    mine: {
      check_in: "毎日チェックイン",
      check_in_box_title: "今月は{dayNum}日チェックインしました",
      check_in_toast: "今日チェックイン済み",
      check_in_tips_title: "操作ルール：",
      check_in_tips_1: "1：クラブメンバーシップカードがアクティブです。",
      check_in_tips_2: "2：顧客の身分証明書は有効である必要があります。",
      check_in_tips_3: "3：連続して7日働くと、1人の女の子と無料でデートできます（選択可能）。",
      cardtitle: "受取カードを入力",
      inp_cardtitle: "受取カード情報を入力してください",
      lab_bankid: "銀行口座番号",
      inp_bank: "銀行名",
      bindCardnote:
        "尊敬するユーザー様、ご自身の資金の安全を確保するために、実名と出金パスワードを設定してください。名前が口座名と一致しない場合、出金はできません",
      bindCard: "カードをバインドする",
      inp_bankid: "銀行口座番号を入力してください",
      select_bank: "銀行を選択してください",
      gamerecord: "ログ",
      Recharge: "チャージ",
      withdraw: "引き出し申請",
      Mypurse: "マイウォレット",
      balance: "残高",
      personalreport: "個人レポート",
      Accountdetails: "銀行カード",
      PersonalCenter: "個人センター",
      announcement: "お知らせ",
      onlineservice: "オンラインカスタマーサービス",
      enter: "タスクリストを完了した後に入ってください",
      servicerecharge: "カスタマーサービスにチャージしてください",
      loginRegister: "ログイン/登録",
      logintip: "ログインすると、より多くのサービスが利用できます！",
      Information: "基本情報",
      herderImg: "プロフィール画像",
      selectimg: "プロフィール画像を選択",
      actualname: "本名",
      sex: "性別",
      male: "男",
      female: "女",
      unknown: "不明",
      bindinginformation: "バインディング情報",
      bound: "バインド済み",
      none: "なし",
      cardinformation: "受取カード情報",
      addpaymentcard: "受取カードを追加",
      cardtips: "ヒント：大手商業銀行をバインドしてください。変更が必要な場合は、オンラインカスタマーサービスに連絡してください。",
      Setname: "名前を設定してから銀行カードをバインドしてください！",
      SetPayPassword: "出金パスワードを設定してから銀行カードをバインドしてください！",
      gendermodification: "性別変更",
      setup: "設定",
      Basicinformation: "基本情報設定",
      loginpassword: "ログインパスワード",
      fundpassword: "資金パスワード",
      withdrawalpassword: "出金パスワードが設定されました。変更が必要な場合はカスタマーサービスに連絡してください",
      Withdrawalcenter: "出金センター",
      Withdrawalsrecord: "出金記録",
      withdrawMoney: "出金金額 (円)",
      withdrshowPopovermin: "1.単一の制限：最低",
      withdrshowPopovermax: "最高",
      widtdrnum: "2.出金回数：1日に最高出金回数",
      withdrsucces: "3.入金時間：一般的に5分以内に入金され、最短2分以内に入金されます",
      Limitdescription: "制限の説明",
      Withdrawimmediately: "今すぐ出金",
      correctamount: "正しい金額を入力してください",
      Setfundpassword: "資金パスワードを設定してください",
      enterthefundpassword: "資金パスワードを入力してください",
      enterthefundpasswordagain: "資金パスワードを再度入力してください",
      Pleasefillinthecomplete: "すべてを入力してください",
      twicepassword: "2回のパスワードが一致しません！",
      Changerealname: "本名を変更",
      Name: "名前",
      pleaseenteryourrealname: "本名を入力してください",
      changeralnametip: "アカウントの安全のため、本名はバインドした銀行口座の名前と一致する必要があります",
      Donotrepeatsettings: "重複設定しないでください！",
      Changeloginpassword: "ログインパスワードを変更",
      OldPassword: "旧パスワード",
      newpassword: "新パスワード",
      inp_OldPassword: "旧パスワードを入力してください",
      inp_newpassword: "新パスワードを入力してください",
      inp_newpassword1: "新パスワードを再度入力してください",
      connect: "連絡",
      servicelistItemInfoText: "24時間365日、誠心でサービスを提供します",
      personalreporttitle: "利益額（円）",
      personalreporttip: "利益計算式 : 当選額 - タスク額",
      personalreportplay_money: "タスク額",
      personalreportrecharge: "入金額",
      personalreportwithdrawal: "出金額",
      personalreportwin_money: "当選額",
      inp_name: "本名を入力してください",
    
      rechargecenter: "入金センター",
      rechargerecord: "入金記録",
      banktype: "銀行カード",
      Depositamount: "入金額",
      depositamount: "一回の最大入金額は",
      minimumdepositamount: "一回の最低入金額は",
      pleasecontact: "問題がある場合はご連絡ください",
      next: "次へ",
      bankinfo: "以下は送金先の銀行口座情報です",
      transferinformation: "送金情報",
      copy: "コピー",
      copysuccessfully: "コピー成功",
      copyerr: "コピー失敗、手動でコピーしてください。",
      uprechargeimg: "送金画像をアップロード",
      selectfileimg: "最初に画像を選択してください",
      pleaseinpmoney: "正しい入金額を入力してください",
      submiterr: "提出失敗",
      rechargetsuccess: "入金申請が完了しました。入金申請が成功しました",
      Amount: "金額",
      illustrate: "説明",
      Submissiontime: "提出時間",
      Reviewtime: "審査時間",
      setlang: "言語設定",
      bank_name : "銀行名",
      branch_name : "支店名称",
      branch_code : "支店番号",
      transaction_type : "取引種類",
      account_number : "口座番号",
      account_holder_name : "口座名義",
      account_holder_name_katakana : "口座名義 (カタ)",
    },
    //   予約
    reserve: {},
    //   選妃
    concubine: {
      choosetitle: "妃を選ぶ",
      city: "都市一覧",
      cityinfo: "このプラットフォームは最もリアルな外部サービス+ビジネス伴遊+同市の興奮を提供し、各ユーザーの個人プライバシーを保護するため、顧客は受付係またはプラットフォームの上級会員の推薦によりのみ加入できます。",
      processprice: "プロセス価格",
      rigtitle1: "どのようなリソースがありますか？",
      rigtitle2: "サービス範囲は？",
      rigcontent1: "インフルエンサー、モデル、キャビンアテンダント、若いモデル、大学生、思い浮かぶものがあれば、このプラットフォームで不可能なことはありません",
      rigcontent2: "同市での無料のデート、任意の場所に全国的に展開、国内の一二線都市には必ずあり、三線都市でも受付係に連絡して予約できます。",
      rigcontent3: "このプラットフォームは最もリアルな外部サービス+ビジネス伴遊+同市の興奮を提供し、各ユーザーの個人プライバシーを保護するため、顧客は受付係またはプラットフォームの上級会員の推薦によりのみ加入できます。",
      Contactanadvisor: "顧問または受付係に連絡してください",
    },
    //   ビデオ
    video: {
      title: "ビデオシアター",
      videoInfocount: "回再生",
      Popularrecommendations: "人気の推奨",
      rechargevideo: "動画を見るには入金してください！",
    },
    "认证": "認証",
    "视频认证": "ビデオ認証",
    "评分": "スコア",
    "身高": "身長",
    "地域": "エリア",
    "降落伞": "パラシュート",
    "现在就答应她": "今すぐ彼女と約束",
    "是的": "はい",
    "不是": "いいえ",
    "胸围": "胸囲",
    "请联系管理员": "管理者に連絡してください",
    "提交成功": "送信成功",
    "顶部": "トップ",
    "支店名称": "支店名",
    "请输入支店名称": "支店名を入力してください",
    "支店号码": "支店番号",
    "请输入支店号码": "支店番号を入力してください",
    "交易类型": "取引種類",
    "请输入交易类型": "取引種類を入力してください",
    "账户号码": "口座番号",
    "请输入账户号码": "口座番号を入力してください",
    "账户名义": " 口座名義",
    "请输入账户名义": " 口座名義を入力してください",
    "账户名义（片假名）": "口座名義 (カタ)",
    "请输入账户名义（片假名）": "口座名義 (カタ)を入力してください",
      "银行名": "銀行名",
  "请输入银行名": "銀行名を入力してください",
};